module.exports = {
  login: {
    userNamePrompt: "Please Enter A UserName",
    passwordPrompt: "Please Enter A Password",
    verifycodePrompt: "Please Enter the Verification Code",
    verifycodeError: "The Verification Code is Incorrect",
    login: "Login",
    reset: "Reset",
    userError: "Enter the Correct UserName and Password!",
  },
  header: {
    personalCenter: "Personal Center",
    logout: "Logout",
  },
  prompt: {
    abnormalRequest: "Abnormal request",
    loginTimeout: "The login timeout",
    deletePromptInfo:
      "This operation permanently deletes the information. Continue or not?",
    promptInfo: "Prompt",
    confirm: "Confirm",
    cancel: "Cancel",
    deleteSuccess: "successfully delete",
    cancelledDelete: "Cancelled delete",
    operateSuccessfully: "Operation Successfully",
    operationFailure: "Operation Failure",
    OperationCancelled: "Operation Cancelled",
    exportNoData: "No Data Can be Exported at the Moment",
    LogonFailure: "Login is Invalid, Please Login Again",
    checkUsername: "",
    checkPassword: "",
    chooseDate: "Please Select a Date",
    chooseMode: "Please Select a Module",
    downloading: "Downloading......",
    downloadTips: "Downloading the files you need, please wait a moment",
  },
  routes: {
    equipmentManagement: "Equipment Management",
    siteInformationList: "Site Information List",
    deviceInformationList: "Equipment Information List",
    equipmentDetails: "Equipment Details",
    BMSInformation: "BMS information",
    PCSInformation: "PCS information",
    DCDCInformation: "DCDC information",
    MPPTInformation: "MPPT information",
    exportData: "Export Data",
    programManage: "Program Management",
    emsProgram: "EMS Program",
    collectProgram: "Acquisition Program",
    softwareUpdate: "Software Update",
    siteUpdate: "Specific Site Updates",
  },
  siteList: {
    numberOfSites: "Total Projects Nos.",
    numberOfOnlineSites: "Online Projects Nos.",
    numberOfOfflineSites: "Offline Projects Nos.",
    siteId: "Project ID",
    inputSiteId: "Please Enter the Project ID",
    inputSiteName: "Please Enter the Project Name",
    checkButton: "Find",
    siteName: "Project Name",
    numberOfDevices: "Equipment Nos.",
    onlineTime: "Online Operation Date",
    siteCondition: "Project State",
    cumulativeCharge: "Cumulative Energy Charged(kW·h)",
    cumulativeDischarge: "Cumulative Energy Discharged(kW·h)",
  },
  deviceList: {
    numberOfDevices: "Total Equipment Nos.",
    runNum: "Operation Equipment Nos.",
    stopNum: "Shut-down Equipment Nos.",
    faultNum: "Break-down Equipment Nos.",
    deviceId: "Equipment Serial No.",
    deviceName: "Equipment Name",
    inputDeviceId: "Please Enter the Equipment Serial No.",
    inputDeviceName: "Please Enter the Equipment Name",
    checkButton: "Find",
    addEquipment: "Add",
    deviceType: "Equipment Type",
    deviceStatus: "Equipment State",
    belongUser: "Customer",
    cumulativeCharge: "Cumulative Energy Charged(kW·h)",
    cumulativeDischarge: "Cumulative Energy Discharged(kW·h)",
    deviceLocation: "Equipment Location",
    operation: "Operation",
    deleteDevice: "Delete",
    editInfo: "Edit",

    changeTime: "Data Update Time",
  },
  deviceDetail: {
    exportData: "Export Data",
    workCondition: "System State",
    workMode: "Operation Mode",
    weather: "Weather",
    temperature: "Temperature",
    place: "Equipment Location",
    cumulativeCharge: "Cumulative Energy Charged",
    cumulativeDischarge: "Cumulative Energy Discharged",
    accumulatedEarnings: "Cumulative Revenue",
    allowDischargeActivePowerLimit:
      "Allowed Active Power Limit for Discharging",
    allowChargeActivePowerLimit: "Allowed Active Power Limit for Charging",
    airStatus: "Air Conditioning State",
    batteryCompartmentTemp: "Battery Room Temp.",
    batteryCompartmentHumidity: "Battery Room Humidity",
    gridVolt: "Grid Voltage",
    gridActivePower: "Grid Active Power",
    loadVolt: "Load Voltage",
    loadActivePower: "Load Active Power",

    totalBatteryVoltage: "Total Battery Voltage",
    totalBatteryCurrent: "Total Battery Current",
    totalChargeOfBatteryPack: "Cumulative Energy Charged",
    totalDischargeOfBatteryPack: "Cumulative Energy Discharged",

    acactivePower: "Total AC Active Power",
    acphaseAVolt: "Total Phase-A Voltage",
    acphaseBVolt: "Total Phase-B Voltage",
    acphaseCVolt: "Total Phase-C Voltage",

    highVoltSideVolt: "Total Voltage of HV Side",
    highVoltSideCurr: "Total Current of HV Side",
    highVoltSidePower: "Total Power of HV Side",
    lowVoltSideAccessVolt: "Total Voltage of LV Side",
    lowVoltSideAccessCurr: "Total Current of LV Side",
    lowVoltSideAccessPower: "Total Power of LV Side",

    systemSOC: "System SOC",
    systemControl: "System Control",
    YesterdayTheDetail: "Yesterday's Operation Details",
    cumulativeChargeYst: "Yesterday's Energy Charged:",
    cumulativeDischargeYst: "Yesterday's Energy Discharged:",
    earningsYesterday: "Yesterday's Revenue:",
    dischargeUtilization: "Discharging Utilization Rate",
    errorLogging: "Error List",
    LookAtAll: "Look at All",
    more: "More",

    notExist: "Not Exist",

    sysFault: "System Fault Information",
    sysWarning: "System Warning Information",
  },
  bmsInfo: {
    bmsStatus: "BMS Status",
    totalBatteryStackCurrent: "Total Battery System Current",
    totalBatteryStackVoltage: "Total Battery System Vol.",
    BatteryStackSOH: "Battery System SOH",

    totalBatteryVoltage: "Battery String Voltage",
    batteryPackSOC: "Battery String SOC",
    maxDischargeCurrent: "Max. Discharge Current",
    minMonomerVolt: "Min. Cell Voltage",
    maxTempBatteryNum: "Tmax Cell Serial No.",
    batteryPackCurrent: "Battery String Current",
    batteryPackSOH: "Battery String SOH",
    maxMonomerVolt: "Max. Cell Voltage",
    minVoltageBatteryNum: "Cell S/N of Min. Voltage",
    minMonomerTemp: "Min. Cell Temp.",
    batteryPower: "Battery String Power",
    maxChargeCurrent: "Max. Charging Current",
    maxVoltBatteryNum: "Max. Voltage Battery S/N",
    maxMonomerTemp: "Max. Cell Temp.",
    minTempBatteryNum: "Cell S/N of Min. Temp.",

    voltageOfAsingleBattery: "Cell Voltage（V）",
    tempOfAsingleBattery: "Cell Temp.（℃）",

    section: "Section",

    bmsFault: "Battery String Fault",
    bmsWarning: "Battery String Warning",
    singleBatteryVoltage: "Cell Voltage",
    singleBatteryTemp: "Cell Temp.",
    batteryTempInfo: "Battery Temperature Information",
    chooseDate: "Choose a Specific Time",
    tempRange: "Temp. Range",
    startTemp: "Start Temp.",
    endTemp: "End Temp.",
    confirm: "Confirm",
    frontView: "Front View",
    backView: "Back View",
    rotate: "Rotate",
  },
  pcsInfo: {
    acactivePower: "AC Active power",
    acreactivePower: "AC Reactive Power",
    acapparentPower: "AC Apparent Power",
    acphaseAVolt: "Phase-A Voltage",
    acphaseBVolt: "Phase-B Voltage",
    acphaseCVolt: "Phase-C Voltage",
    acphaseACurr: "Phase-A Current",
    acphaseBCurr: "Phase-B Current",
    acphaseCCurr: "Phase-C Current",
    acfrequency: "AC Frequency",
    dcbusVolt: "DC Voltage",
    dccurrent: "DC Current",
    phaseAActivePower: "Phase-A Active Power",
    phaseBActivePower: "Phase-B Active Power",
    phaseCActivePower: "Phase-C Active Power",

    acVoltage: "AC Voltage",
  },
  dcdcInfo: {
    totalVoltageHighVoltageSide: "Total Voltage of HV Side",
    totalCurrentHighVoltageSide: "Total Current of HV Side",
    totalPowerHighVoltageSide: "Total Power of HV Side",
    totalVoltageLowVoltageSide: "Total Voltage of LV Side",
    totalCurrentLowVoltageSide: "Total Current of LV Side",
    totalPowerLowVoltageSide: "Total Power of LV Side",
    voltageHighVoltageSide: "Voltage of HV Side",
    currentHighVoltageSide: "Current of HV Side",
    powerHighVoltageSide: "Power of HV Side",
    voltageLowVoltageSide: "Voltage of LV Side",
    currentLowVoltageSide: "Current of LV Side",
    powerLowVoltageSide: "Power of LV Side ",
  },

  addDevice: {
    addingDeviceInformation: "Adding Equipment Information",
    editingDeviceInformation: "Editing Equipment Information",
    deviceId: "Equipment ID",
    deviceName: "Equipment Name",
    company: "User Company",
    productType: "Equipment Type",
    productTypeInput: "Please Select the Equipment Type",
    add: "Add",
    edit: "Edit",
  },
  echartsData: {
    activePowerOfGrid: "Grid Active Power",
    loadActivePower: "Load Active Power",
  },
  inputBox: {
    dateSelection: "Date Selection",
    selectADate: "Please Select a Date",
  },

  sysData: {
    online: "Online",
    offline: "Offline",
    stop: "Stop",
    operation: "Operation",
    breakDowm: "Break-down",

    offGrid: "Off-grid",
    onOffGrid: "On/Off-grid",
    peak: "Peak Shaving and Load Shifting",
    backUp: "Back-up Power",
    pv: "PV Access",
    customizedMode: "Customized Mode",
    AutomaticOperationMode: "Automatic Operation Mode",

    weather: "Sunny",
    sz: "ShenZhen",

    shutdown: "Shutdown",
    startingUp: "Start up",
  },
  exportData: "Export Data",
  common: {
    time: "Time",
    date: "Date",
    startDate: "Start Date",
    endDate: "End Date",
    mode: "Module",
    export: "Export",
  },
  programManage: {
    emsProgramId: "EMS program No.",
    emsProgramName: "EMS Program Name",
    collectProgramName: "Acquisition Program Name",
    emsProgramVersion: "EMS program Version No.",
    collectProgramId: "Acquisition Program No.",
    collectProgramVersion: "Acquisition Program Version No.",
    programId: "Program No.",
    programName: "Program Name",
    programVersion: "Program Version",
    programDescribe: "Elaborate",
    site: "Applicable Site",
    createTime: "Creation Time",
    upload: "Upload Program",
    inputEmsId: "Please Enter the EMS program No.",
    inputEmsVersion: "Please Enter the EMS program Version No.",
    inputColletId: "Please Enter the Acquisition Program No.",
    inputCollectVersion: "Please Enter the Acquisition Program Version No.",
    addProgram: "Added Program Version Information",
    editProgram: "Edit Program Version Information",
  },
};
