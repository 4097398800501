<template>
  <div
    :class="type"
    :style="{ color: color, 'font-size': iconSize }"
    class="iconfont"
    @click="_onClick"
  />
</template>

<script>
import "@/assets/iconfont/iconfont.css";
// import "./style/iconfont.css";
export default {
  name: "VIcon",
  props: {
    type: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#333333",
    },
    size: {
      type: [Number, String],
      default: 16,
    },
  },
  computed: {
    iconSize() {
      return this.size + "px";
    },
  },
  methods: {
    _onClick() {
      this.$emit("click");
    },
  },
};
</script>
