// import { construct } from "core-js/fn/reflect";

export function handleData(data) {
  let obj = {},
    res = [],
    len = data.length;
  for (let i = 0; i < len; i++) {
    obj[data[i]["id"]] = data[i];
  }
  // debugger;
  // console.log(obj);
  for (let j = 0; j < len; j++) {
    let list = data[j];
    let parentList = obj[list["parentId"]];
    // console.log("type", list["type"]);
    if (list["type"] === 1) {
      if (parentList) {
        if (!parentList["children"]) {
          parentList["children"] = [];
        }
        parentList["children"].push(list);
      } else {
        res.push(list);
      }
    }
  }

  return res;
}

export function handlePermissionBtn(data) {
  console.log(999999999, data);
  if (data != null) {
    let btnList = [];
    data.map((item, index) => {
      if (item.type === 2) {
        btnList.push(item);
      }
    });
    console.log(99999999999999, btnList);
    return btnList.map((item) => item.label);
  } else {
    return [];
  }
  // const { btnList } = data.reduce((memo, item) => {
  //   console.log(memo, item);
  //   if (item.type === 2) {
  //     memo.btnList.push(item);
  //   }
  //   // console.log(99999999999999, btnList);
  //   return memo;
  // });
  // console.log(99999999999999, btnList);
}

export function orderByTime(prop, align) {
  //prop：对象数组排序的键，
  //align：排序方式，"positive"正序，"inverted"倒序。
  return function (a, b) {
    var value1 = a[prop];
    var value2 = b[prop];
    if (align == "positive") {
      //正序
      return new Date(value1) - new Date(value2);
    } else if (align == "inverted") {
      //倒序
      return new Date(value2) - new Date(value1);
    }
  };
}
