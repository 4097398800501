import Vue from "vue";
import Vuex from "vuex";
import slider from "./slider";
// import state from "./state";
// import mutations from "./mutations";
import { handleData, handlePermissionBtn } from "@/utils/util";

Vue.use(Vuex);

const store = new Vuex.Store({
  // state: state,
  // mutations: mutations,
  state: {
    isLogin: false,
    deviceInfo: {},
    modeInfo: {},
    // menuTree: [],
    siteOptions: [],
    menuList: [],
    permissionBtn: [],
  },
  getters: {
    isLogin: (state) => state.isLogin,
    siteOptions: (state) => state.siteOptions,
    menuTree(state) {
      return handleData(state.menuList);
    },
    // menuBtn(state) {
    //   return handlePermissionBtn(state.permissionBtn);
    // },
  },
  mutations: {
    userStatus(state, flag) {
      state.isLogin = flag;
    },
    changeMsg(state, value) {
      state.msg = value;
    },
    changeDeviceInfo(state, value) {
      state.deviceInfo = value;
    },
    changeModeInfo(state, value) {
      // console.log("$$$$$$$$$$$$$$$$$", value, state.modeInfo);
      state.modeInfo = value;
    },
    setMenuTree(state, menu) {
      state.menuList = menu;
      // console.tab("$$$$$$$$$$$$$$$$$", menu);
    },
    getSiteInfo(state, value) {
      state.siteOptions = value;
    },
    setPermissinBtn(state, permissionBtn) {
      state.permissionBtn = permissionBtn;
    },
  },
  actions: {
    setUser({ commit }, flag) {
      commit("userStatus", flag);
    },
  },

  // // state: {
  // //   //存放的键值对就是所要管理的状态
  // //   islogin: Cookie.get("user") != null ? true : false,
  // //   user: {
  // //     username: Cookie.get("user") != null ? Cookie.get("user").username : "",
  // //     avater: Cookie.get("user") != null ? Cookie.get("user").avater : "",
  // //     sex: Cookie.get("user") != null ? Cookie.get("user").sex : "",
  // //   },
  // // },
  // // mutations: {
  // //   // 登录方法，用来向用户状态中存储用户信息
  // //   login(state, user) {
  // //     console.log(user);
  // //     state.islogin = true;
  // //     state.user.username = user.username;
  // //     state.user.avater = user.avater;
  // //     state.user.sex = user.sex;
  // //   },
  // //   // 退出登录的方法，用来将用户状态给清空
  // //   logout(state) {
  // //     state.islogin = false;
  // //     state.user.username = "";
  // //     state.user.avater = "";
  // //     state.user.sex = "";
  // //   },
  // // },
  modules: {
    slider: slider,
  },
});

export default store;
