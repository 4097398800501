import Vue from "vue";

Vue.filter("num", function (val, fix = 2) {
  if (val < 0) {
    val = parseFloat(val);
    val = val.toFixed(fix); // 保留小数2位
    val = Math.abs(val);
    val = "" + val; // 转换成字符串
    // console.log("llllll", val);
    var int = val.slice(0, fix * -1 - 1); // 拿到整数
    var ext = val.slice(fix * -1 - 1); // 获取到小数
    int = int.split("").reverse().join(""); // 翻转整数
    var temp = ""; // 临时变量
    for (var i = 0; i < int.length; i++) {
      temp += int[i];
      if ((i + 1) % 3 == 0 && i != int.length - 1) {
        temp += ","; // 每隔三个数字拼接一个逗号
      }
    }
    temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
    temp = temp + ext; // 整数小数拼接
    return "-" + temp; // 返回
  } else if (val != "-" && val != "" && val != null && val > 0) {
    val = parseFloat(val);
    val = val.toFixed(fix); // 保留小数2位
    val = "" + val; // 转换成字符串
    int = val.slice(0, fix * -1 - 1); // 拿到整数
    ext = val.slice(fix * -1 - 1); // 获取到小数
    int = int.split("").reverse().join(""); // 翻转整数
    temp = ""; // 临时变量
    for (i = 0; i < int.length; i++) {
      temp += int[i];
      if ((i + 1) % 3 == 0 && i != int.length - 1) {
        temp += ","; // 每隔三个数字拼接一个逗号
      }
    }
    temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
    temp = temp + ext; // 整数小数拼接
    return temp; // 返回
  } else if (val == 0 || val == "0") {
    return val;
  } else {
    return "-";
  }
});

Vue.filter("amount", function (val) {
  if (val > 10000) {
    return val / 10000;
  }
});

Vue.filter("zhiBaoNum", function (val, fix = 2) {
  if (val < 0) {
    return "-";
  } else if (val != "-" && val != "" && val != null && val > 0) {
    val = parseFloat(val);
    val = val.toFixed(fix); // 保留小数2位
    val = "" + val; // 转换成字符串
    let int = val.slice(0, fix * -1 - 1); // 拿到整数
    let ext = val.slice(fix * -1 - 1); // 获取到小数
    int = int.split("").reverse().join(""); // 翻转整数
    var temp = ""; // 临时变量
    for (let i = 0; i < int.length; i++) {
      temp += int[i];
      if ((i + 1) % 3 == 0 && i != int.length - 1) {
        temp += ","; // 每隔三个数字拼接一个逗号
      }
    }
    temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
    temp = temp + ext; // 整数小数拼接
    return temp; // 返回
  } else if (val == 0 || val == "0") {
    return val;
  } else {
    return "-";
  }
});
