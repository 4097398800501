/**
 * 设备管理
 */
//  import I18n from "@/lang";
const parentName = "收益明细";
export default [
  {
    path: "/dailyReport",
    name: "DailyReport",
    component: () => import("@/pages/Benefit/dailyReport.vue"),
    meta: {
      title: "日报表",
      parentTitle: parentName,
      isLogin: true,
    },
  },
  {
    path: "/monthlyReport",
    name: "MonthlyReport",
    component: () => import("@/pages/Benefit/monthlyReport.vue"),
    meta: {
      title: "月报表",
      parentTitle: parentName,
      isLogin: true,
    },
  },
  // {
  //   path: "/benefitAnalysis",
  //   name: "BenefitAnalysis",
  //   component: () => import("@/pages/Benefit/benefitAnalysis.vue"),
  //   meta: {
  //     title: "收益分析",
  //     parentTitle: parentName,
  //     isLogin: true,
  //   },
  // },
  {
    path: "/billInfo",
    name: "BillInfo",
    component: () => import("@/pages/Benefit/billInfo.vue"),
    meta: {
      title: "账单信息",
      parentTitle: parentName,
      isLogin: true,
    },
  },
];
