module.exports = {
  login: {
    userNamePrompt: "请输入用户名",
    passwordPrompt: "请输入密码",
    verifycodePrompt: "请输入验证码",
    verifycodeError: "验证码不正确",
    login: "登录",
    reset: "重置",
    userError: "请输入正确的用户名、密码！",
  },
  header: {
    personalCenter: "个人中心",
    logout: "退出",
  },
  prompt: {
    abnormalRequest: "请求异常",
    loginTimeout: "登录超时，请重新登录",
    deletePromptInfo: "此操作将永久删除该条信息, 是否继续?",
    promptInfo: "提示",
    confirm: "确定",
    cancel: "取消",
    deleteSuccess: "删除成功",
    cancelledDelete: "已取消删除",
    operateSuccessfully: "操作成功",
    operationFailure: "操作失败",
    OperationCancelled: "操作取消",
    exportNoData: "暂无导出的数据",
    LogonFailure: "登录失效，请重新登录",
    checkUsername: "请输入正确的用户名",
    checkPassword: "请输入正确的密码",
    chooseDate: "请选择日期",
    chooseMode: "请选择模块",
    downloading: "下载中......",
    downloadTips: "正在下载您所需要的文件，请稍后",
  },
  routes: {
    equipmentManagement: "设备管理",
    siteInformationList: "站点信息列表",
    deviceInformationList: "设备信息列表",
    equipmentDetails: "设备详情",
    BMSInformation: "BMS信息",
    PCSInformation: "PCS信息",
    DCDCInformation: "DCDC信息",
    MPPTInformation: "MPPT信息",
    exportData: "运行数据导出",
    programManage: "程序管理",
    emsProgram: "EMS程序",
    collectProgram: "采集程序",
    softwareUpdate: "软件更新",
    siteUpdate: "具体站点更新",
  },
  siteList: {
    numberOfSites: "总站点数",
    numberOfOnlineSites: "在线站点数",
    numberOfOfflineSites: "离线站点数",
    siteId: "站点ID",
    inputSiteId: "请输入站点ID",
    inputSiteName: "请输入站点名称",
    checkButton: "查 询",
    siteName: "站点名称",
    numberOfDevices: "设备数量",
    onlineTime: "上线时间",
    siteCondition: "站点状态",
    cumulativeCharge: "累计充电量 (kW·h)",
    cumulativeDischarge: "累计放电量 (kW·h)",
  },
  deviceList: {
    numberOfDevices: "总设备数",
    runNum: "运行设备数",
    stopNum: "停机设备数",
    faultNum: "故障设备数",
    deviceId: "站内编号",
    deviceName: "设备名称",
    inputDeviceId: "请输入设备ID",
    inputDeviceName: "请输入设备名称",
    checkButton: "查 询",
    addEquipment: "新 增",
    deviceType: "设备类型",
    deviceStatus: "设备状态",
    belongUser: "所属用户",
    cumulativeCharge: "累计充电量 (kW·h)",
    cumulativeDischarge: "累计放电量 (kW·h)",
    deviceLocation: "设备所在地",
    operation: "操作",
    deleteDevice: "删除",
    editInfo: "编辑",

    changeTime: "数据更新时间",
  },
  deviceDetail: {
    exportData: "数据导出",
    workCondition: "系统状态",
    workMode: "运行模式",
    weather: "天气",
    temperature: "温度",
    place: "设备所在地",
    cumulativeCharge: "累计充电量",
    cumulativeDischarge: "累计放电量",
    accumulatedEarnings: "累计收益",
    allowDischargeActivePowerLimit: "允许放电有功功率限值",
    allowChargeActivePowerLimit: "允许充电有功功率限值",
    airStatus: "空调状态",
    batteryCompartmentTemp: "电池仓温度",
    batteryCompartmentHumidity: "电池仓湿度",
    gridVolt: "电网电压",
    gridActivePower: "电网有功功率",
    loadVolt: "负载电压",
    loadActivePower: "负载有功功率",

    totalBatteryVoltage: "电池组总电压",
    totalBatteryCurrent: "电池组总电流",
    totalChargeOfBatteryPack: "电池组总充电量",
    totalDischargeOfBatteryPack: "电池组总放电量",

    acactivePower: "总交流有功功率",
    acphaseAVolt: "总交流A相电压",
    acphaseBVolt: "总交流B相电压",
    acphaseCVolt: "总交流C相电压",

    highVoltSideVolt: "高压侧总电压",
    highVoltSideCurr: "高压侧总电流",
    highVoltSidePower: "高压侧总功率",
    lowVoltSideAccessVolt: "低压侧总电压",
    lowVoltSideAccessCurr: "低压侧总电流",
    lowVoltSideAccessPower: "低压侧总功率",

    systemSOC: "系统SOC",
    systemControl: "系统控制",
    YesterdayTheDetail: "昨日明细",
    cumulativeChargeYst: "昨日充电量：",
    cumulativeDischargeYst: "昨日放电量：",
    earningsYesterday: "昨日收益：",
    dischargeUtilization: "放电量利用率",
    errorLogging: "故障列表",

    LookAtAll: "查看全部",
    more: "更多",

    notExist: "暂无模块",

    sysFault: "系统故障信息",
    sysWarning: "系统告警信息",
  },
  bmsInfo: {
    bmsStatus: "电池组状态",
    totalBatteryStackCurrent: "电池堆总电流",
    totalBatteryStackVoltage: "电池堆总电压",
    BatteryStackSOH: "电池堆SOH",

    totalBatteryVoltage: "电池簇总电压",
    batteryPackSOC: "电池簇SOC",
    maxDischargeCurrent: "最大放电电流",
    minMonomerVolt: "最低单体电压",
    maxTempBatteryNum: "最高温度电池号",
    batteryPackCurrent: "电池簇总电流",
    batteryPackSOH: "电池簇SOH",
    maxMonomerVolt: "最高单体电压",
    minVoltageBatteryNum: "最低电压电池号",
    minMonomerTemp: "最低单体温度",
    batteryPower: "电池簇功率",
    maxChargeCurrent: "最大充电电流",
    maxVoltBatteryNum: "最高电压电池号",
    maxMonomerTemp: "最高单体温度",
    minTempBatteryNum: "最低单体温度电池号",

    voltageOfAsingleBattery: "单节电池电压（V）",
    tempOfAsingleBattery: "单节电池温度（℃）",
    section: "节",

    bmsFault: "电池组故障",
    bmsWarning: "电池组告警",
    singleBatteryVoltage: "单节电池电压",
    singleBatteryTemp: "单节电池温度",
    batteryTempInfo: "电池温度信息",
    chooseDate: "选择具体时间",
    tempRange: "温度范围",
    startTemp: "开始温度",
    endTemp: "结束温度",
    confirm: "确定",
    frontView: "正视图",
    backView: "后视图",
    rotate: "转动",
  },
  pcsInfo: {
    acactivePower: "交流有功功率",
    acreactivePower: "交流无功功率",
    acapparentPower: "交流视在功率",
    acphaseAVolt: "交流A相电压",
    acphaseBVolt: "交流B相电压",
    acphaseCVolt: "交流C相电压",
    acphaseACurr: "交流A相电流",
    acphaseBCurr: "交流B相电流",
    acphaseCCurr: "交流C相电流",
    acfrequency: "交流频率",
    dcbusVolt: "直流电压",
    dccurrent: "直流电流",
    phaseAActivePower: "A相有功功率",
    phaseBActivePower: "B相有功功率",
    phaseCActivePower: "C相有功功率",
    acVoltage: "交流电压",
  },
  dcdcInfo: {
    totalVoltageHighVoltageSide: "高压侧总电压",
    totalCurrentHighVoltageSide: "高压侧总电流",
    totalPowerHighVoltageSide: "高压侧总功率",
    totalVoltageLowVoltageSide: "低压侧总电压",
    totalCurrentLowVoltageSide: "低压侧总电流",
    totalPowerLowVoltageSide: "低压侧总功率",
    voltageHighVoltageSide: "高压侧电压",
    currentHighVoltageSide: "高压侧电流",
    powerHighVoltageSide: "高压侧功率",
    voltageLowVoltageSide: "低压侧电压",
    currentLowVoltageSide: "低压侧电流",
    powerLowVoltageSide: "低压侧功率",
  },
  addDevice: {
    addingDeviceInformation: "新增设备信息",
    editingDeviceInformation: "编辑设备信息",
    deviceId: "设备ID",
    deviceName: "设备名称",
    company: "用户公司",
    productType: "产品类型",
    productTypeInput: "请选择产品类型",
    add: "新增",
    edit: "修改",
  },
  echartsData: {
    activePowerOfGrid: "电网有功功率",
    loadActivePower: "负载有功功率",
  },
  inputBox: {
    dateSelection: "日期筛选",
    selectADate: "请选择日期",
  },

  sysData: {
    online: "在线",
    offline: "离线",
    stop: "停机",
    operation: "运行",
    breakDowm: "故障",

    offGrid: "离网",
    onOffGrid: "并离网",
    peak: "削峰填谷",
    backUp: "备电模式",
    pv: "PV效益模式",
    customizedMode: "定制模式",
    AutomaticOperationMode: "自动运行模式",

    weather: "晴朗",
    sz: "深圳",

    shutdown: "关机",
    startingUp: "开机",
  },
  exportData: "数据导出",
  common: {
    time: "时间",
    date: "日期",
    startDate: "开始日期",
    endDate: "结束日期",
    mode: "模块",
    export: "导出",
  },
  programManage: {
    emsProgramId: "EMS程序编号",
    emsProgramName: "EMS程序名称",
    collectProgramName: "采集程序名称",
    emsProgramVersion: "EMS程序版本号",
    collectProgramId: "采集程序编号",
    collectProgramVersion: "采集程序版本号",
    programId: "程序编号",
    programName: "程序名称",
    programVersion: "程序版本",
    programDescribe: "详细描述",
    site: "适用站点",
    createTime: "创建时间",
    upload: "上传程序",
    inputEmsId: "请输入EMS程序编号",
    inputEmsVersion: "请输入EMS程序版本号",
    inputColletId: "请输入采集程序编号",
    inputCollectVersion: "请输入采集程序版本号",
    addProgram: "新增程序版本信息",
    editProgram: "编辑程序版本信息",
  },
};
