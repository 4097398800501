import Vue from "vue";
import VueRouter from "vue-router";
import Benefit from "./benefit";
import UserManage from "./userManage";
// import router from "@/router";
import store from "@/store";
import { getMenu } from "@/api/login.js";
import { handlePermissionBtn } from "@/utils/util";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../pages/login.vue"),
    meta: {
      isLogin: false,
    },
  },
  {
    path: "/",
    name: "Main",
    component: () => import("../pages/main.vue"),
    meta: {
      isLogin: true,
    },
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../pages/Home/home.vue"),
        meta: {
          isLogin: true,
          title: "首页",
          // parentTitle: ,
        },
      },
      {
        path: "/produceType",
        name: "produceType",
        component: () => import("../pages/Site/produce.vue"),
        meta: {
          isLogin: true,
          title: "站点信息",
          // parentTitle: ,
        },
      },
      {
        path: "/site",
        name: "Site",
        component: () => import("../pages/Site/site.vue"),
        meta: {
          isLogin: true,
          title: "产品类型",
          // parentTitle: ,
        },
      },
      {
        path: "/siteDetail",
        name: "SiteDetail",
        component: () => import("../pages/Site/siteDetail.vue"),
        meta: {
          isLogin: true,
          title: "站点详细信息",
          parentTitle: "站点信息",
        },
      },
      {
        path: "/topology",
        name: "topology",
        component: () => import("../pages/Site/topology.vue"),
        meta: {
          isLogin: false,
          title: "站点详细信息",
          parentTitle: "站点拓扑",
        },
      },
      {
        path: "/device",
        name: "device",
        component: () => import("../pages/Site/Device/device.vue"),
        meta: {
          isLogin: true,
          title: "设备信息",
          parentTitle: "站点信息",
        },
      },
      {
        path: "/inspection",
        name: "inspection",
        component: () => import("../pages/Site/Device/inspection.vue"),
        meta: {
          isLogin: true,
          title: "设备巡检看板",
          parentTitle: "设备信息",
        },
      },
      {
        path: "/rate",
        name: "Rate",
        component: () => import("../pages/Rate/rate.vue"),
        meta: {
          isLogin: true,
          title: "费率信息",
          // parentTitle: ,
        },
      },
      {
        path: "/customerList",
        name: "CustomerList",
        component: () => import("../pages/Customer/customerList.vue"),
        meta: {
          isLogin: true,
          title: "客户信息",
          parentTitle: "客户管理",
        },
      },
      {
        path: "/message",
        name: "Message",
        component: () => import("../pages/Message/message.vue"),
        meta: {
          isLogin: true,
          title: "我的消息",
          parentTitle: "",
        },
      },
      {
        path: "/reportForm",
        name: "reportForm",
        component: () => import("../pages/Report/form.vue"),
        meta: {
          isLogin: true,
          title: "方案表单",
        },
      },
      {
        path: "/report",
        name: "report",
        component: () => import("../pages/Report/report.vue"),
        meta: {
          isLogin: true,
          title: "方案",
        },
      },
      ...Benefit,
      ...UserManage,
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("stewardToken");
  if (to.meta.isLogin && token == null) {
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  } else {
    const expireTime = localStorage.getItem("stewardExpireTime");
    let startTime = new Date().getTime();

    if (startTime > expireTime) {
      localStorage.removeItem("stewardToken");
      console.log(startTime > expireTime);
      if (to.path === "/login") {
        next();
      } else {
        // this.$message({
        //   type: "warning",
        //   message: "登录过期，请重新登录",
        // });
        next("/login");
      }
    } else {
      let { data } = await getMenu({});
      let data2 = handlePermissionBtn(data.data);
      console.log("==================", data2);
      store.commit("setMenuTree", data.data);
      store.commit("setPermissinBtn", data2);
      if (to.path === "/") {
        next("/home");
      } else {
        next();
      }
    }
  }
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
