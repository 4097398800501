import store from "@/store";
import Vue from "vue";
// import { DirectiveFunction } from "vue/types/umd";

// el：指令所绑定的元素，可以用来直接操作 DOM
// binding：一个对象，包含以下 property：
const inserted = function (el, binding, vnode) {
  const buttonId = binding.value;
  const userButtonList = store.state["permissionBtn"] || [];
  if (!userButtonList.includes(buttonId)) {
    if (binding.modifiers.disabled) {
      const btnType = binding.arg || "elbutton";
      if (btnType === "elbutton") {
        el.setAttribute("disabled", "disabled");
        el.classList.add("is-disabled");
      }
    } else {
      el.remove();
    }
  }
};

const directives = {
  auth: {
    inserted,
  },
};

export default {
  install() {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key]);
    });
  },
};

// import Vue from "vue";
// import store from "@/store";
// // 检测是否有权限
// // 使用Vue.directive声明自定义指令btn-key
// export const buttonPermissions = Vue.directive("auth", {
//   inserted(el, binding) {
//     debugger;
//     let buttonKey = binding.value;
//     // 代表某个元素需要通过权限验证
//     console.log(333333333, buttonKey);
//     if (buttonKey) {
//       let key = checkKey(buttonKey);
//       if (!key) {
//         //没有权限
//         el.remove(); //删除按钮
//       }
//     } else {
//       throw new Error("缺少唯一指令");
//     }
//   },
// });

// // 检测传入的元素key是否可以显示
// function checkKey(key) {
//   // 获取权限数组
//   let permissionData = store.state["permissionBtn"] || [];
//   console.log(permissionData);
//   //如果传入的元素key不在权限数组里，则不可显示
//   let index = permissionData.indexOf(key);
//   console.log(index);
//   if (index > -1) {
//     return true;
//   } else {
//     return false;
//   }
// }
