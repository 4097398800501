
export default {
  install(Vue) {
    // 防止重复点击,节流
    Vue.directive("preventReClick", {
      inserted(el, binding) {
        el.addEventListener("click", () => {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
              // 设置默认时间为3秒
            }, binding.value || 3000);
          }
        });
      },
    });
  },
};
