const silder = {
  namespaced: true,
  state: {
    isCollapse: true,
  },
  mutations: {
    COLLAPSE_MENU: (state) => {
      state.isCollapse = !state.isCollapse;
    },
  },
};

export default silder;
