import axios from "axios";
import Vue from "vue";
import { Loading } from "element-ui";
import I18n from "@/lang";
import QS from "qs";
const https = require("https");
class Http {
  constructor() {
    this.instance = this.create();
    this.instance.interceptors.request.use(
      (config) => {
        if (config.dataType === "json") return config;
        let token = localStorage.getItem("stewardToken");
        // 没有token处理
        if (!token) {
          return config;
        }
        // config.headers.Authorization = token || '';
        config.headers = {
          token: token || "",
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  create() {
    return axios.create({
      // baseURL: "https://benefitbserver1.chinacloudsites.cn/",
      baseURL: "https://benefit.vilionvpp.com/",
      // baseURL: "http://api.vilionsmart.com/",

      // baseURL: "http://192.168.1.98:8031/",
      timeout: 60000,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    });
  }

  post2(url, data = {}) {
    return this.request({
      url,
      data: QS.stringify(data),
      method: "post",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        // httpsAgent: agent,
      },
    });
  }
  post(url, data = {}) {
    return this.request({
      url,
      data: data,
      method: "post",
      // headers: {
      //   httpsAgent: agent,
      // },
    });
  }

  get(url, data = {}) {
    return this.request({
      url,
      method: "get",
      params: data,
      // headers: {
      //   httpsAgent: agent,
      // },
    });
  }

  getFile(url, data = {}) {
    return this.instance.request({
      url,
      method: "get",
      params: data,
      responseType: "blob",
    });
  }

  getFileDownload(url, data = {}) {
    return new Promise((resolve, reject) => {
      this.instance.request({
        url,
        method: "post",
        responseType: "blob",
        params: data,
        headers: {
          "content-type": "application/json",
        },
      });
      // .then(
      //   (res) => {
      //     let data = res.data;
      //     const str = res.headers["content-disposition"];
      //     let filename;
      //     if (str) {
      //       filename = str.match(/filename=(\S*?)(;|$)/)[1];
      //     }
      //     filename = filename || "未命名";
      //     resolve({ data, filename: decodeURI(filename) });
      //   },
      //   (error) => {
      //     reject(error);
      //   }
      // )
      // .catch((error) => {
      //   reject(error);
      // });
    });
  }

  request(config) {
    // if (!config.data || !config.data.no_ele_loading) {
    //   this.loading = Loading.service({
    //     lock: true,
    //     fullscreen: true,
    //     background: "rgba(0, 0, 0, 0)",
    //     text: "加载中",
    //     customClass: "dan-loading",
    //     spinner: "el-icon-loading",
    //   });
    // }
    return new Promise((resolve) => {
      this.instance.request(config).then(
        (data) => {
          Vue.nextTick(() => {
            this.loading && this.loading.close();
          });
          if (data.status == 200) {
            resolve(data);
          }
        },
        (err) => {
          this.error(err);
        }
      );
    });
  }

  error(data) {
    Vue.nextTick(() => {
      this.loading.close();
    });
    let message = data.message || data.msg;
    if (message == undefined) message = I18n.t("prompt.abnormalRequest");
    var errorCode = [401];
    if (errorCode.indexOf(data.code) > -1) {
      Vue.prototype.$message.error(I18n.t("prompt.loginTimeout"));
    }
    // else if (data.code == void 0) {
    //   Vue.prototype.$message.error(message);
    // } else {
    //   Vue.prototype.$message.error(message);
    // }

    const error = new Error(message);
    return Promise.reject(error);
  }
}

const http = new Http();

export { http };
