import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
// const language = Cookies.get('language');
const language = localStorage.getItem("languageSet");
const i18n = new VueI18n({
  // 语言标识，后面会用做切换和将用户习惯存储到本地浏览器
  locale: language || "zh-CN", //  语言标识
  messages: {
    "zh-CN": require("./config/zn"),
    "en-CN": require("./config/en"),
  },
  silentTranslationWarn: true,
});
console.log(language);
export default i18n;
