/**
 * 设备管理
 */
//  import I18n from "@/lang";
const parentName = "用户管理";
export default [
  {
    path: "/userList",
    name: "UserList",
    component: () => import("@/pages/User/userList.vue"),
    meta: {
      title: "账号管理",
      parentTitle: parentName,
      isLogin: true,
    },
  },
  {
    path: "/role",
    name: "Role",
    component: () => import("@/pages/User/role.vue"),
    meta: {
      title: "角色管理",
      parentTitle: parentName,
      isLogin: true,
    },
  },
  {
    path: "/log",
    name: "Log",
    component: () => import("@/pages/User/log.vue"),
    meta: {
      title: "登录日志",
      parentTitle: parentName,
      isLogin: true,
    },
  },
];
