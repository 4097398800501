import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "element-ui/lib/theme-chalk/index.css";
import "./element-ui/index";
import i18n from "@/lang";
import "@/assets/style/index.styl";
import store from "./store";
import preventReClick from "./utils/preventRepeatClick";
import * as echarts from "echarts";
// import {directive} from "@/utils/directives";
import directive from "@/utils/directives";
import Icon from "../src/components/Icon";
import scroll from "vue-seamless-scroll";
import "@/assets/iconfont/iconfont.css";
import "./utils/filter.js";
import "echarts-liquidfill";
Vue.prototype.$echarts = echarts;
Vue.use(scroll);
// import ElementUI from "./element-ui/index";
// Vue.use(ElementUI);
Vue.component("Icon", Icon);
Vue.use(directive);
Vue.use(preventReClick);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
